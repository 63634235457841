
function phoneOrEmail() {
    if (document.getElementById('phoneCheck').checked) {
      document.getElementById('phoneIsChecked').style.display = 'block';
      document.getElementById('emailIsChecked').style.display = 'none';
      return true;
    }
    else if (document.getElementById('emailCheck').checked) {
      document.getElementById('emailIsChecked').style.display = 'block';
      document.getElementById('phoneIsChecked').style.display = 'none';
      return false;
  }
  return false;
  }
