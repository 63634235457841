function viewPassword() {
  var passwordInput = document.getElementById('pass');
  //var passwordInput = document.getElementById('cpass');
  // var passwordInput = document.getElementById('npass');
  var passStatus = document.getElementById('shpass');

  if (passwordInput.type == 'password') {
    passwordInput.type = 'text';
    passStatus.className = 'fa fa-eye field-icon';
  }
  else {
    passwordInput.type = 'password';
    passStatus.className = 'fa fa-eye-slash field-icon';
  }
}

function viewCPassword() {
  var passwordInput = document.getElementById('cpass');
  //var passwordInput = document.getElementById('cpass');
  // var passwordInput = document.getElementById('npass');
  var passStatus = document.getElementById('shcpass');

  if (passwordInput.type == 'password') {
    passwordInput.type = 'text';
    passStatus.className = 'fa fa-eye field-icon';
  }
  else {
    passwordInput.type = 'password';
    passStatus.className = 'fa fa-eye-slash field-icon';
  }
}
function viewCCPassword() {
  var passwordInput = document.getElementById('ccpass');
  //var passwordInput = document.getElementById('cpass');
  // var passwordInput = document.getElementById('npass');
  var passStatus = document.getElementById('shccpass');

  if (passwordInput.type == 'password') {
    passwordInput.type = 'text';
    passStatus.className = 'fa fa-eye field-icon';
  }
  else {
    passwordInput.type = 'password';
    passStatus.className = 'fa fa-eye-slash field-icon';
  }
}

