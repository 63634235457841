function searchFunction() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("searchInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");
    // Loop through all table rows, and hide those who don't match the search query
  for (i = 0; i < tr.length; i++) {
    // td = tr[i].getElementsByTagName("td")[0];
    alltags = tr[i].getElementsByTagName("td");
    isFound = false;
    for (j = 0; j < alltags.length; j++) {
      td = alltags[j];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
          j = alltags.length;
          isFound = true;
        }
      }
    }
    if (!isFound && tr[i].id !== "headerTable") {
      tr[i].style.display = "none";
    }
  }
}
