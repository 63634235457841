function autoFillDisplayName() {
  var inp1 = document.getElementsByName('first_name')[0]
    var inp2 = document.getElementsByName('last_name')[0]
    var inp3 = document.getElementsByName('display_name')[0]

  inp1.addEventListener('keyup', function (e) {
    inp3.value = inp1.value + " " + inp2.value;
    inp2.addEventListener('keyup', function (e) {
      inp3.value = inp1.value + " " + inp2.value;
    });
  });
  
}
