function checkPassword() {
  const password = document.getElementById('pass').value;

  // Checking for the password requirements
  const lengthValid = password.length >= 8;
  const uppercaseValid = /[A-Z]/.test(password);
  const lowercaseValid = /[a-z]/.test(password);
  const numberValid = /[0-9]/.test(password);
  const specialValid = /[!@$%*?&~`#^()_={}|:;"'<,>.\/\\*\+\-_]/.test(password);

  // Update the status of each requirement
  updateRequirement('length', lengthValid);
  updateRequirement('uppercase', uppercaseValid);
  updateRequirement('lowercase', lowercaseValid);
  updateRequirement('number', numberValid);
  updateRequirement('special', specialValid);
}

function updateRequirement(id, isValid) {
  const requirement = document.getElementById(id);

  // If the requirement is valid, add the 'valid' class, else remove it
  if (isValid) {
    requirement.classList.add('valid');
  } else {
    requirement.classList.remove('valid');
  }
}
